* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
  }
  
  body {
    margin: 0;
    padding: 0;
    background-color: white;
  }
  
  .grid-container{
    width: 100%;
    max-width: 1200px;  
    margin: 0;
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
  
  .grid-gallery {
    scroll-behavior: smooth;
    width: 100%;
    max-width: 1200px;  
    height: 400px;
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 250px;
    grid-auto-flow: row; */
    grid-template-columns: repeat(auto-fit, minmax(18vw, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(160px,1fr);
    overflow-x: auto;
    grid-gap: 10px;
  }
  
  .grid-gallery::-webkit-scrollbar {
    display: none;
  }
  
  .grid-gallery .grid-item {
    position: relative;
    background-color: #efefef;
    overflow: hidden;
  }
  
  .grid-gallery .grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .grid-gallery .grid-item:hover img {
    transform: scale(1.1);
  }
  
  .grid-gallery .grid-item a {
    cursor: zoom-in;
  }
  
  .grid-gallery .grid-item:nth-child(5n - 4) {
    grid-column: span 2;
    grid-row: span 2;
  }
  
  .grid-gallery .grid-item:nth-child(5n - 3) {
    grid-column: span 2;
    grid-row: span 1;
  }
  
  .grid-gallery .grid-item:nth-child(5n - 2) {
    grid-column: span 2;
    grid-row: span 1;
  }
  
  
  
  /* Let's make it responsive */
  @media (max-width: 768px) {
    .grid-gallery {
      grid-template-columns: repeat(auto-fill, minmax(50vw, 1fr));
      /* grid-auto-rows: 250px; */
    }
  
    .grid-gallery .grid-item {
      grid-column: unset;
      grid-row: unset;
    }
    
    .grid-gallery .grid-item:nth-child(5n - 3) {
      grid-column: unset;
      grid-row: unset;
    }
    
    .grid-gallery .grid-item:nth-child(5n - 2) {
      grid-column: unset;
      grid-row: unset;
    }
  
  }
  